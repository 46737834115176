import Link from 'next/link';
import { ReactElement, useState } from 'react';

import AuthPopup from '@components/Auth';

import { colors } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import { featureStyles } from '../styles';

export interface CarouselItemProps {
    item: {
        title: ReactElement;
        Icon: ({ isWhiteColor }: { isWhiteColor: boolean }) => ReactElement;
        link?: string;
        text: ReactElement;
    };
    idx: number;
}
const CarouselItem = ({ item, idx }: CarouselItemProps) => {
    const Icon = item.Icon;
    const [showWhiteIcon, setShowWhiteIcon] = useState(false);
    const [isAuthPopup, setIsAuthPopup] = useState(false);
    const { sm } = useMedia();

    return (
        <>
            <div
                key={item.link}
                onMouseOver={() => setShowWhiteIcon(true)}
                onMouseLeave={() => setShowWhiteIcon(false)}
            >
                {idx !== 1 ? (
                    <Link legacyBehavior href={item.link || ''} passHref prefetch={false}>
                        <a aria-label={item.title.toString()} css={featureStyles(sm)}>
                            <div>{showWhiteIcon ? <Icon isWhiteColor={true} /> : <Icon isWhiteColor={false} />}</div>
                            <div
                                css={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-beetwen',
                                }}
                            >
                                <div
                                    css={{
                                        fontWeight: 500,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    {item.title}
                                </div>
                                <div
                                    css={{
                                        color: colors.textSecondGrey,
                                        transition: 'color .3s',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {item.text}
                                </div>
                            </div>
                        </a>
                    </Link>
                ) : (
                    <div css={{ cursor: 'pointer' }} onClick={() => setIsAuthPopup(true)}>
                        <div css={featureStyles(sm)}>
                            <div>{showWhiteIcon ? <Icon isWhiteColor={true} /> : <Icon isWhiteColor={false} />}</div>
                            <div
                                css={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-beetwen',
                                }}
                            >
                                <div
                                    css={{
                                        fontWeight: 500,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    {item.title}
                                </div>
                                <div
                                    css={{
                                        color: colors.textSecondGrey,
                                        transition: 'color .3s',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {item.text}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <AuthPopup isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} isRegistration />
        </>
    );
};

export default CarouselItem;
