import { ReactNode } from 'react';

import { useSeoContext } from '@context/seo';

import LoadWrapper from '@components/controls/LoadWrapper';

import { VisuallyHidden, scale, typography } from '@scripts/gds';

import { SeoHead } from './SeoHead';

type PageWrapperProps = {
    h1?: string;
    title?: string;
    error?: string;
    isLoading?: boolean;
    children: ReactNode;
    className?: string;
    seoProps?: Record<string, string>;
};

const PageWrapper = ({ h1, title, isLoading = false, error, children, className, seoProps }: PageWrapperProps) => {
    const seoContexData = useSeoContext();

    const seo = {
        ...seoContexData,
        ...seoProps,
    };

    return (
        <>
            <SeoHead {...seo} />
            <LoadWrapper isLoading={isLoading} error={error}>
                <main
                    css={{
                        padding: 0,
                        paddingBottom: scale(11),
                        height: '100%',
                        minHeight: '50vh',
                    }}
                    className={className}
                >
                  

                    {children}
                </main>
            </LoadWrapper>
        </>
    );
};

export default PageWrapper;
